import React from 'react'
import { Container, Row, Col } from 'react-bootstrap'
import SignInCard from 'components/common/cards/SignInCard'

export const SignInBlock = ({ title, subtitle }) => {
	return (
		<Container style={{ marginBottom: 50, marginTop: 50 }}>
			<Row>
				<Col xs='12' md={{ offset: 1, span: 10 }} xl={{ offset: 3, span: 6 }}>
					<SignInCard title={title} subtitle={subtitle} />
				</Col>
			</Row>
		</Container>
	)
}
