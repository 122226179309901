import { useQuery, useMutation, useQueryClient } from '@tanstack/react-query'
import { updateUserProfile, fetchUser, fetchUserNotifications } from 'utility/queries/users'

export const useUser = (slug) =>
	useQuery({
		enabled: !!slug,
		queryKey: ['user', slug],
		queryFn: () => fetchUser({ slug }),
	})

export const useUpdateUser = (slugId) => {
	const queryClient = useQueryClient()
	return useMutation({
		mutationKey: ['user', slugId],
		mutationFn: (data) => updateUserProfile({ slugId, data }),
		onSuccess: () => {
			console.log("INVALIDATE QUERIES: 'user', slugId")
			queryClient.invalidateQueries({ queryKey: ['user', slugId] })
		},
	})
}

export const useUserNotifications = (userSlug) => {
	return useQuery({
		enabled: userSlug ? true : false,
		queryKey: ['userNotifications', userSlug],
		queryFn: () => fetchUserNotifications({ userSlug }),
		refetchOnWindowFocus: true,
		refetchOnMount: true,
		refetchOnReconnect: true,
		refetchIntervalInBackground: true,
	})
}
