import React from 'react';
import styled from 'styled-components';

import colors from 'utility/colors';

const StyledShadowBox = styled.div`
  width: ${props => props.width};
  background-color: ${() => colors.SKELETON};
  height: ${props => props.height};
  margin: 5px 0px 5px 0px;
  border-radius: 3px;
`

const SkeletonBox = ({width='100%', height='20px', style={}}) => {
  return <StyledShadowBox width={width} height={height} style={style}/>
}

export default SkeletonBox;