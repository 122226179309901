import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

const Icon = ({icon='times', size='20', color='black', solid=false, light=false, style={}, onClick=() => {}, ...props}) => {
  if(!icon || icon.length < 1) return null;

  const computedStyle = {
    fontSize: size,
    width: size,
    height: '100%',
    color: color,
    ...style
  }

  return (
    <FontAwesomeIcon onClick={onClick} icon={[`fa${solid ? 's' : light ? 'l' : 'r'}`, icon]} style={computedStyle} {...props}/>
  )
}

export default Icon;
