import React from 'react';
import { Row, Col } from 'react-bootstrap';

import Card from 'components/common/cards/Card';
import SkeletonBox from 'components/common/skeletons/SkeletonBox';
import SkeletonTitle from 'components/common/skeletons/SkeletonTitle';

const SkeletonBlog = ({height}) => {
  return <Card style={{height: height ? height : 'auto'}}>
    <Row>
      <Col>
        <SkeletonBox width={'100%'} height={'200px'}/>
      </Col>
    </Row>
    <Row>
      <Col>
        <SkeletonTitle/>
        <SkeletonBox width={'90%'}/>
      </Col>
    </Row>
  </Card>
}

export default SkeletonBlog;