import React from 'react'
import { Container, Row, Col } from 'react-bootstrap'

import SkeletonBlog from 'components/common/skeletons/SkeletonBlog'
import SkeletonCard from 'components/common/skeletons/SkeletonCard'

export const SkeletonPage = () => {
  return (
    <Container style={{ marginTop: 50, marginBottom: 50 }}>
      <Row>
        <Col xs="12" md="6" lg="3">
          <SkeletonCard />
        </Col>
        <Col xs="12" md="6" lg="3">
          <SkeletonCard />
        </Col>
        <Col xs="12" md="6" lg="3">
          <SkeletonCard />
        </Col>
        <Col xs="12" md="6" lg="3">
          <SkeletonCard />
        </Col>
      </Row>
      <Row>
        <Col xs="12" md="12">
          <SkeletonBlog height={400} />
        </Col>
      </Row>
    </Container>
  )
}
