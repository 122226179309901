export const getRandomNumber = (min, max) => {
  return Math.floor(Math.random() * (max - min + 1) + min);
}

export const toMoney = (number) => {
  if(!number)
    return 0;

  if(typeof number === 'string')
    return number;

  // return number.replace(/\d(?=(\d{3})+\.)/g, '$&,');
  return number.toFixed(precision).replace(/\d(?=(\d{3})+\.)/g, '$&,');
}

export const moneyWithComma = (numberString) => {
  if(!numberString)
    return numberString
    
  return numberString && numberString.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")
}

export const formatMoneyCents = (value) => {
  if(!value)
    return value
  
  return moneyWithComma((value / 100))
}

export function formatMoneyCentsWithDecimals(amount, decimalCount = 2, decimal = ".", thousands = ",") {
  const amountDolars = amount / 100;
  try {
    decimalCount = Math.abs(decimalCount);
    decimalCount = isNaN(decimalCount) ? 2 : decimalCount;

    const negativeSign = amountDolars < 0 ? "-" : "";

    let i = parseInt(amountDolars = Math.abs(Number(amountDolars) || 0).toFixed(decimalCount)).toString();
    let j = (i.length > 3) ? i.length % 3 : 0;

    return negativeSign +
      (j ? i.substr(0, j) + thousands : '') +
      i.substr(j).replace(/(\d{3})(?=\d)/g, "$1" + thousands) +
      (decimalCount ? decimal + Math.abs(amountDolars - i).toFixed(decimalCount).slice(2) : "");
  } catch (e) {
    console.log(e)
  }
};

export const formatPhoneNumber = (phoneNumberString) => {
  var cleaned = ('' + phoneNumberString).replace(/\D/g, '').slice(0,10)
  var match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/)
  if (match) {
    return '(' + match[1] + ') ' + match[2] + '-' + match[3]
  }
  return cleaned
}

export const moneyRound = (num) => {
  return Math.ceil(num * 100) / 100;
}