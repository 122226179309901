import React from "react";
import styled from "styled-components";

const StyledText = styled.p`
  font-family: "Poppins", sans-serif;
  font-size: ${(props) => `${props.size}px`};
  margin-bottom: 5px;
  padding-bottom: 2px;
  line-height: ${(props) => `${props.size + 6}px`};
  color: ${(props) => props.color};
  font-weight: ${(props) => (props.bold ? "bold" : "normal")};
  text-align: ${(props) => props.align};
`;

const Text = ({
  children,
  size = 16,
  align = "left",
  color = "#000000",
  bold,
  tooltip,
  ...props
}) => {
  return (
    <StyledText
      size={size}
      bold={bold}
      align={align}
      color={color}
      data-tip={tooltip}
      {...props}
    >
      {children}
    </StyledText>
  );
};

export default Text;
