import Link from 'next/link'

const _Link = (props) => {
  let data = {}
  if (props.to) data.href = props.to

  return (
    <Link {...props} {...data} passHref={true}>
      <a {...props}>{props.children}</a>
    </Link>
  )
}

export default _Link
