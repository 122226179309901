import React from 'react';
import styled from 'styled-components';
import Text from 'components/common/Text';
import colors from 'utility/colors';

const StyledFieldError = styled(Text)`
  margin-top: 5px;
  color: ${colors.WHITE};
`;

const FieldError = ({children, size=16, align='left', bold, style={}, ...props}) => {
  return <StyledFieldError style={{marginTop: 0, ...style}} size={size} bold={bold} align={align} {...props}>{children}</StyledFieldError>
}

export default FieldError;